import axios from 'axios';

const API_URL = 'http://localhost/api.php';

export const registerUser = async (login, password) => {
    try {
        const response = await axios.get(API_URL, {
            params: {
                act: 'User',
                method: 'register',
                login: login,
                password: password
            }
        });
        return response.data;
    } catch (error) {
        console.error('Error registering user:', error);
        return { success: false, result: error.message };
    }
};

export const loginUser = async (login, password) => {
    try {
        const response = await axios.get(API_URL, {
            params: {
                act: 'User',
                method: 'login',
                login: login,
                password: password
            }
        });
        return response.data;
    } catch (error) {
        console.error('Error logging in:', error);
        return { success: false, result: error.message };
    }
};

export const getUserInfo = async (id) => {
    try {
        const response = await axios.get(API_URL, {
            params: {
                act: 'User',
                method: 'getUserInfo',
                id: id
            }
        });
        return response.data;
    } catch (error) {
        console.error('Error getting user info:', error);
        return { success: false, result: error.message };
    }
};

export const setUserInfo = async (id, fio, age) => {
    try {
        const response = await axios.get(API_URL, {
            params: {
                act: 'User',
                method: 'setUserInfo',
                id: id,
                fio: fio,
                age: age
            }
        });
        return response.data;
    } catch (error) {
        console.error('Error setting user info:', error);
        return { success: false, result: error.message };
    }
};

export const getGoods = async () => {
    try {
        const response = await axios.get(API_URL, {
            params: {
                act: 'User',
                method: 'getGoods'
            }
        });
        return response.data;
    } catch (error) {
        console.error('Error fetching goods:', error);
        return { success: false, result: error.message };
    }
};

export const buyGoods = async (id, money, cost, count) => {
    try {
        const response = await axios.get(API_URL, {
            params: {
                act: 'User',
                method: 'buyGoods',
                id: id,
                money: money,
                cost: cost,
                count: count
            }
        });
        return response.data;
    } catch (error) {
        console.error('Error buying goods:', error);
        return { success: false, result: error.message };
    }
};
