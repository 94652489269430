import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { getUserInfo, setUserInfo } from '../services/api';
import { useLocation, useHistory } from 'react-router-dom';
import './UserInfoStyles.css';

const UserInfo = () => {
    const [userInfo, setUserInfoState] = useState(null);
    const [newFio, setNewFio] = useState('');
    const [newAge, setNewAge] = useState('');
    const location = useLocation();
    const userId = new URLSearchParams(location.search).get('user');
    const history = useHistory();

    useEffect(() => {
        const fetchUserInfo = async () => {
            if (userId) {
                const response = await getUserInfo(userId);
                if (response.success && response.result) {
                    setUserInfoState(response.result);
                    setNewFio(response.result.fio);
                    setNewAge(response.result.age);
                } else {
                    console.error('Ошибка при получении информации о пользователе:', response);
                }
            }
        };
        fetchUserInfo();
    }, [userId]);

    const handleUpdateUserInfo = async () => {
        const response = await setUserInfo(userId, newFio, newAge);
        if (response.success) {
            console.log('Информация о пользователе успешно обновлена');
            setUserInfoState((prevState) => ({
                ...prevState,
                fio: newFio,
                age: newAge
            }));
        } else {
            console.error('Ошибка при обновлении информации о пользователе:', response.result);
        }
    };

    const handleLogout = () => {
        history.push('/login');
    };

    return (
        <div className="user-info-container">
            {userInfo && (
                <div>
                    <p>Имя: {userInfo.fio}</p>
                    <p>Возраст: {userInfo.age}</p>
                    <p>Деньги: {userInfo.money}</p>
                    <input type="text" value={newFio} onChange={(e) => setNewFio(e.target.value)} placeholder="Введите новое имя" />
                    <input type="number" value={newAge} onChange={(e) => setNewAge(e.target.value)} placeholder="Введите новый возраст" />
                    <button onClick={handleUpdateUserInfo}>Обновить информацию</button>
                </div>
            )}
            <div> {/* Пустой div для создания пространства */}
                <Link to="/login">
                    <button onClick={handleLogout}>Выйти</button>
                </Link>
            </div>
            <div> {/* Еще один пустой div для создания пространства */}
                <Link to={`/purchase?user=${userId}`}>
                    <button>Перейти к покупкам</button>
                </Link>
            </div>
        </div>
    );
};

export default UserInfo;
