import React from 'react';
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom';
import UserInfo from './components/UserInfo';
import PurchaseTab from './components/PurchaseTab'; // Импортируем компонент PurchaseTab
import LoginForm from './components/LoginForm';
import RegistrationForm from './components/RegistrationForm';

const App = () => {
    return (
        <Router>
            <Switch>
                <Route path="/userInfo" component={UserInfo} />
                <Route path="/purchase" component={PurchaseTab} /> {/* Добавляем маршрут для вкладки "Покупки" */}
                <Route path="/login" component={LoginForm} />
                <Route path="/registration" component={RegistrationForm} />
                {/* Другие маршруты вашего приложения */}
            </Switch>
        </Router>
    );
};

export default App;
