import React, { useState, useEffect } from 'react';
import { buyGoods, getGoods, getUserInfo } from '../services/api';
import { Link } from 'react-router-dom';
import './PurchaseTabStyles.css';

const PurchaseTab = () => {
    const [products, setProducts] = useState([]);
    const [selectedProduct, setSelectedProduct] = useState(null);
    const [quantity, setQuantity] = useState(1);
    const [userId, setUserId] = useState(null);
    const [userInfo, setUserInfo] = useState(null);

    useEffect(() => {
        const urlParams = new URLSearchParams(window.location.search);
        const userIdParam = urlParams.get('user');
        if (userIdParam) {
            setUserId(userIdParam);
        }
    }, []);

    useEffect(() => {
        const fetchData = async () => {
            if (userId) {
                const response = await getUserInfo(userId);
                if (response.success && response.result) {
                    setUserInfo(response.result);
                } else {
                    console.error('Failed to fetch user info:', response);
                }
            }
        };
        fetchData();
    }, [userId]);

    useEffect(() => {
        const fetchProducts = async () => {
            const response = await getGoods();
            if (response.success && response.result) {
                setProducts(response.result);
            } else {
                console.error('Failed to fetch products:', response);
            }
        };
        fetchProducts();
    }, []);

    const handleProductChange = (event) => {
        const productId = event.target.value;
        const selected = products.find(product => product.name === productId);
        setSelectedProduct(selected);
    };

    const handleQuantityChange = (event) => {
        setQuantity(event.target.value);
    };

    const handleSubmit = async (event) => {
        event.preventDefault();
        if (selectedProduct && userInfo) {
            const response = await buyGoods(userId, userInfo.money, selectedProduct.cost, quantity);
            if (response.success) {
                console.log('Товар успешно куплен');
                setUserInfo(prevUserInfo => ({ ...prevUserInfo, money: response.result }));
            } else {
                console.error('Ошибка при покупке товара:', response.result);
            }
        }
    };

    return (
        <div className="purchase-tab-container">
            <h2>Форма для покупки товара</h2>
            <div>
                {userInfo && (
                    <p>Счет пользователя: {userInfo.money}</p>
                )}
            </div>
            <form onSubmit={handleSubmit}>
                <div>
                    <label htmlFor="product">Выберите товар:</label>
                    <select id="product" onChange={handleProductChange} required>
                        <option value="">Выберите товар</option>
                        {products.map(product => (
                            <option key={product.name} value={product.name}>{product.name} - {product.cost}</option>
                        ))}
                    </select>
                </div>
                {selectedProduct && (
                    <div>
                        <p>Стоимость товара: {selectedProduct.cost}</p>
                        <label htmlFor="quantity">Количество:</label>
                        <input
                            type="number"
                            id="quantity"
                            value={quantity}
                            onChange={handleQuantityChange}
                            min="1"
                            required
                        />
                    </div>
                )}
                <button type="submit">Купить</button>
                <Link to={`/userInfo?user=${userId}`}>
                    <button>Вернуться</button>
                </Link>
            </form>
        </div>
    );
};

export default PurchaseTab;
