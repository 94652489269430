import React, { useState } from 'react';
import { useHistory } from 'react-router-dom';
import { registerUser } from '../services/api';
import './RegistrationFormStyles.css'; // Импортируем файл со стилями

const RegistrationForm = () => {
    const [login, setLogin] = useState('');
    const [password, setPassword] = useState('');
    const [error, setError] = useState('');
    const history = useHistory();

    const handleSubmit = async (e) => {
        e.preventDefault();
        try {
            const response = await registerUser(login, password);
            console.log(response);
            if (response.success) {
                history.push(`/userInfo?user=${response.result}`);
            } else {
                setError(response.result);
            }
        } catch (error) {
            console.error('Error registering user:', error);
            setError('Error registering user. Please try again later.');
        }
    };

    return (
        <div className="registration-form-container">
            <form onSubmit={handleSubmit}>
                <input type="text" placeholder="Login" value={login} onChange={(e) => setLogin(e.target.value)} />
                <input type="password" placeholder="Password" value={password} onChange={(e) => setPassword(e.target.value)} />
                <button type="submit">Регистрация</button>
            </form>
            {error && <p className="error-message">{error}</p>}
            <p>Есть аккаунт? <a href="/login">Зайдите!</a></p>
        </div>
    );
};

export default RegistrationForm;
