import React, { useState } from 'react';
import { Link, useHistory } from 'react-router-dom';
import { loginUser } from '../services/api';
import UserInfo from './UserInfo';
import RegistrationForm from './RegistrationForm';
import './LoginFormStyles.css'; // Импортируем файл со стилями

const LoginForm = () => {
    const [login, setLogin] = useState('');
    const [password, setPassword] = useState('');
    const [error, setError] = useState('');
    const [userId, setUserId] = useState(null);
    const history = useHistory();

    const handleSubmit = async (e) => {
        e.preventDefault();
        try {
            const response = await loginUser(login, password);
            console.log(response);
            if (response.success) {
                history.push('/userInfo?user=' + response.result);
            } else {
                setError('Failed to login. Please check your credentials.');
            }
        } catch (error) {
            console.error('Error logging in:', error);
            setError('Failed to login. Please check your credentials.');
        }
    };

    return (
        <div className="login-form-container">
            {userId ? (
                <UserInfo userId={userId} />
            ) : (
                <div>
                    <form onSubmit={handleSubmit}>
                        <input type="text" placeholder="Login" value={login} onChange={(e) => setLogin(e.target.value)} />
                        <input type="password" placeholder="Password" value={password} onChange={(e) => setPassword(e.target.value)} />
                        <button type="submit">Логин</button>
                        {error && <p className="error-message">{error}</p>}
                    </form>
                    <p>Нет аккаунта? <Link to="/registration">Зарегистрируйтесь!</Link></p>
                </div>
            )}
        </div>
    );
};

export default LoginForm;
